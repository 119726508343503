import React, { useCallback, useState, useRef } from "react"
import axios from 'axios'
import Contact from './style'
import SectionTitle from './Components/SectionTitle'
import { Col, Container, Row } from 'react-bootstrap'
import ContactData from "~data-yaml/contact.yml"
import { GoogleReCaptchaProvider, GoogleReCaptcha, useGoogleReCaptcha, reCAPTCHA } from "react-google-recaptcha-v3"

export default function ContactOne(){

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [company, setCompany] = useState('')
  const [message, setMessage] = useState('')

  //reCAPTCHA
 // const captchaRef = useRef(null)
 // const { GoogleReCaptcha } = require('react-google-recaptcha-v3')
  //const { executeRecaptcha } = useGoogleReCaptcha()
  //const [token, setToken] = useState('')
  //const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
 // const onloadCallback = function() {
 //   console.log("reCAPTCHA has loaded!");
 //   GoogleReCaptcha.reset();
 // };

  const send = async (e) => {
    e.preventDefault()
    /*
         reCAPTCHA.ready(function () {
         reCAPTCHA.execute('6Lcy2mAhAAAAACE5zfk1k2yFbIRkU5AzFtr2nBI4', { action: 'submit' }).then(function (token) {
           // Add your logic to submit to your backend server here.
           console.log('refreshed token:', token);
           document.getElementById("token").value = token;
         });
       });
   */
    // Check if the captcha was skipped or not
    //if (!executeRecaptcha) {
    //  console.log('Execute recaptcha not yet available');
    //  return;
    // }

    //This is the same as grecaptcha.execute on traditional html script tags
    //const result = await executeRecaptcha('Submit')
    // setToken(result) //--> grab the generated token by the reCAPTCHA

    axios.post('https://1rlckjb3w6.execute-api.us-east-1.amazonaws.com/prod/email', {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      company: company,
      msg: message,
      contactus: "1"
    }).then((res) => {
      console.log(res)
      //console.log(token)
      document.getElementById("message1").style.color = "blue";
      document.getElementById("message1").innerHTML = "Form submitted successfully!";
    }).catch(function(error) {
      document.getElementById("message1").style.color = "red";
      document.getElementById("message1").innerHTML = JSON.stringify(error);
    }).finally(() => {
      //setRefreshReCaptcha(r => !r);
      e.target.reset()
    });
  }

  return(
    <Contact backgroundColor="#f3f4f6">
      <Container>
        <Row>
          <Col className="col-xl-7 col-lg-7">
            <Contact.Box >
              <SectionTitle
                subTitle={ContactData.subtitle}
                title={ContactData.title}
                text={ContactData.text}
                subTitleProps={{mb:"10px"}}
                titleProps={{mb:"10px",as:"h2"}}
                mb="50px" />
            </Contact.Box>
            <Contact.From>
              <form id="contact-form"  method="post" onSubmit={(e) => send(e)}>
                  <Row>
                    <Col xs="12" className="col-lg-6 mb-4">
                      <div className="form-floating">
                        <input className="form-control" placeholder="First name" id="floatinginput1" required onChange={(e) => setFirstName(e.target.value)} />
                        <label htmlFor="floatinginput1">First Name*</label>
                      </div>
                    </Col>
                    <Col xs="12" className="col-lg-6 mb-4">
                      <div className="form-floating">
                        <input className="form-control" placeholder="Last name" id="floatinginput2" required onChange={(e) => setLastName(e.target.value)} />
                        <label htmlFor="floatinginput2">Last Name*</label>
                      </div>
                    </Col>
                    <Col xs="12" className="col-lg-6 mb-4">
                      <div className="form-floating">
                        <input className="form-control" placeholder="Email address" id="floatinginput3" required onChange={(e) => setEmail(e.target.value)} />
                        <label htmlFor="floatinginput3">Email*</label>
                      </div>
                    </Col>
                    <Col xs="12" className="col-lg-6 mb-4">
                      <div className="form-floating">
                        <input className="form-control" placeholder="Phone number"  id="floatinginput4" required onChange={(e) => setPhone(e.target.value)} />
                        <label htmlFor="floatinginput4">Phone number*</label>
                      </div>
                    </Col>
                    <Col xs="12" className="col-lg-12">
                      <div className="form-floating">
                        <input className="form-control" placeholder="Company" id="floatinginput5" required onChange={(e) => setCompany(e.target.value)} />
                        <label htmlFor="floatinginput5">Company*</label>
                      </div>
                      <Row className="align-items-center mt-3">
                        <div className="form-floating">
                          <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea" required onChange={(e) => setMessage(e.target.value)} />
                          <label htmlFor="floatingTextarea6">Your Message Here* </label>
                        </div>
                      </Row>
                      <Row className="align-items-center mt-3">
                        <div className="col-md-8 col-lg-7 col-md-6 col-xl-8 pt-3">
                          <div className="form-check d-flex align-items-center">
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                              * Required fields
                            </label>
                          </div>
                        </div>
                      </Row>
                    </Col>
                    <Col xs="12" className="col-lg-12">
                      <Row className="align-items-center mt-3">
                        <div className="col-md-8 col-lg-7 col-md-6 col-xl-8 pt-3">
                          <div className="form-check d-flex align-items-center">
                            <label className="form-check-label" htmlFor="flexCheckDefault" id="message1">
                            </label>
                          </div>
                        </div>
                        <Col xs="12" className="col-md-4 col-lg-5 col-xl-4 text-md-end pt-3">
                          <Contact.Button>Send Message</Contact.Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
              </form>
            </Contact.From>
          </Col>
          <Col xs="12" className="col-xl-5 col-lg-5">
            <Contact.WidgetsBox className="contact-widget-box">
              <Contact.WidgetsBoxTitle as="h2">Get In Touch</Contact.WidgetsBoxTitle>
              <Contact.WidgetsBoxText as="p"></Contact.WidgetsBoxText>
              <Row>
                <Col xs="12" className="col-lg-12 col-sm-6">
                  <Contact.Widgets>
                    <Contact.WidgetsIcon>
                      <i className="fas fa-map-marker-alt" />
                    </Contact.WidgetsIcon>
                    <Contact.WidgetsBoxBody>
                      <Contact.WidgetsTitle as="h3">Address :</Contact.WidgetsTitle>
                      <Contact.WidgetsText as="p">{ContactData.address}</Contact.WidgetsText>
                    </Contact.WidgetsBoxBody>
                  </Contact.Widgets>
                </Col>
                <Col xs="12" className="col-lg-12 col-sm-6 active">
                  <Contact.Widgets>
                    <Contact.WidgetsIcon className="active">
                      <i className="fas fa-envelope" />
                    </Contact.WidgetsIcon>
                    <Contact.WidgetsBoxBody>
                      <Contact.WidgetsTitle as="h3">Email :</Contact.WidgetsTitle>
                      <Contact.WidgetsText as="p">{ContactData.email}</Contact.WidgetsText>
                    </Contact.WidgetsBoxBody>
                  </Contact.Widgets>
                </Col>
                <Col xs="12" className="col-lg-12 col-sm-6">
                  <Contact.Widgets>
                    <Contact.WidgetsIcon>
                      <i className="fas fa-phone-alt" />
                    </Contact.WidgetsIcon>
                    <Contact.WidgetsBoxBody>
                      <Contact.WidgetsTitle as="h3">Phone :</Contact.WidgetsTitle>
                      <Contact.WidgetsText as="p">{ContactData.phone}</Contact.WidgetsText>
                    </Contact.WidgetsBoxBody>
                  </Contact.Widgets>
                </Col>
              </Row>
            </Contact.WidgetsBox>
          </Col>
        </Row>
      </Container>
    </Contact>

  )
}
