import React from "react";
import HeroSection from "~sections/Common/Hero";
import ContactSection from "~sections/Contact/ContactOne/ContactSection";
import contact from "~data-yaml/contact.yml"
import FooterSection from "~sections/Common/Footer";
import { PageWrapper } from "~components/Core";
import HeroBg from "~image/heroimages/contact/hero_bg.jpg"
import heroOverlay from "~image/heroimages/contact/overlay.png"

const header = {
  headerClasses: "site-header site-header--menu-end dark-header site-header--sticky",
  containerFluid:false,
  darkLogo:false,
}

export default function contactOne() {
  return (
    <PageWrapper headerConfig={header}>
      <HeroSection
        title={contact.hero.title}
        subtitle={contact.hero.subtitle}
        text={contact.hero.text}
        HeroBg={HeroBg}
        Overlay={heroOverlay}
      />
      <ContactSection/>
        <FooterSection/>
    </PageWrapper>
  )
}
